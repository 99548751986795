import React, {useMemo, useState, useEffect, useContext} from 'react'
import {useQuery, useSubscription, gql, useMutation} from '@apollo/client'

import {
  Queue,
  filterAppointments,
  sortAppointments,
  Empty,
  hydrateAppointment,
  QueueHeading,
  AppointmentEditorProps,
  AppointmentEditor,
} from '../components/appointments'
import {CalendarIcon, Skeleton} from '../components'
import {Appointment, AppointmentEvent, isAdmin, Status} from '../models'
import Page from '../components/page'
import {AppContext} from '../context'
import {DragDropContext} from 'react-beautiful-dnd'
import DatePicker from 'react-date-picker/dist/entry.nostyle'
import {APPOINTMENT_EVENT_SELECT_FRAGMENT, APPOINTMENT_SELECT_FRAGMENT} from '../queries'
import {CompleteGetAppointmentsQuery} from '../graphql/CompleteGetAppointmentsQuery'
import {CompleteNewAppointmentSubscription} from '../graphql/CompleteNewAppointmentSubscription'
import {CompleteNewAppointmentEventSubscription} from '../graphql/CompleteNewAppointmentEventSubscription'
import {AppointmentEventInput, Role} from '../graphql/Global'
import {CompleteModifyAppointmentMutation} from '../graphql/CompleteModifyAppointmentMutation'
import {toDateString} from '../../common'

export const GET_APPOINTMENTS = gql`
  query CompleteGetAppointmentsQuery($date: String) {
    appointments(date: $date) {
      ...AppointmentSelectFragment
    }
  }
  ${APPOINTMENT_SELECT_FRAGMENT}
`

export const MODIFY_APPOINTMENT = gql`
  mutation CompleteModifyAppointmentMutation($input: AppointmentModificationInput!) {
    modifyAppointment(input: $input) {
      ...AppointmentSelectFragment
    }
  }
  ${APPOINTMENT_SELECT_FRAGMENT}
`

export const NEW_APPOINTMENT_SUBSCRIPTION = gql`
  subscription CompleteNewAppointmentSubscription($tid: String!) {
    newAppointment(tid: $tid) {
      ...AppointmentSelectFragment
    }
  }
  ${APPOINTMENT_SELECT_FRAGMENT}
`

export const NEW_APPOINTMENT_EVENT_SUBSCRIPTION = gql`
  subscription CompleteNewAppointmentEventSubscription($tid: String!) {
    newAppointmentEvent(tid: $tid) {
      ...AppointmentEventSelectFragment
    }
  }
  ${APPOINTMENT_EVENT_SELECT_FRAGMENT}
`

const CompleteContent: React.FC = () => {
  const [date, setDate] = useState<Date>(new Date())
  const [busy, setBusy] = useState<boolean>(false)
  const [events, setEvents] = useState<Record<string, AppointmentEvent[]>>({})
  const [newAppointments, setNewAppointments] = useState<Appointment[]>([])
  const [appointmentEditorProps, setAppointmentEditorProps] = useState<AppointmentEditorProps>()
  const {error, data, loading, refetch} = useQuery<CompleteGetAppointmentsQuery>(GET_APPOINTMENTS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      date: toDateString(date),
    },
  })
  const [modifyAppointment] = useMutation<CompleteModifyAppointmentMutation>(MODIFY_APPOINTMENT)

  if (error) console.log(error)

  const {currentLocation, user} = useContext(AppContext)
  const tid = user?.tid

  const appointments: Appointment[] = useMemo(
    () =>
      data?.appointments &&
      [...data?.appointments, ...newAppointments]
        .map((appointment: Appointment) => hydrateAppointment(appointment, events))
        .filter((a) => a.locationId === currentLocation?.id),
    [data?.appointments, newAppointments, events, currentLocation?.id]
  )

  const {data: newAppointmentData} = useSubscription<CompleteNewAppointmentSubscription>(NEW_APPOINTMENT_SUBSCRIPTION, {
    variables: {tid},
  })

  useEffect(() => {
    if (!newAppointmentData) return
    const {newAppointment} = newAppointmentData

    const current = newAppointments.find((a) => a.id === newAppointment.id)
    if (current) return

    setNewAppointments([...newAppointments, newAppointment])
  }, [newAppointmentData, newAppointments])

  const {data: newAppointmentEventData} = useSubscription<CompleteNewAppointmentEventSubscription>(
    NEW_APPOINTMENT_EVENT_SUBSCRIPTION,
    {variables: {tid}}
  )
  useEffect(() => {
    if (!newAppointmentEventData) return
    const {newAppointmentEvent} = newAppointmentEventData

    const currentEvents = events[newAppointmentEvent.appointmentId]
    const current = currentEvents?.slice().sort((a, b) => b.createdAt - a.createdAt)[0]
    if (current?.createdAt >= newAppointmentEvent.createdAt) return

    setEvents({
      ...events,
      [newAppointmentEvent.appointmentId]: currentEvents
        ? [...currentEvents, newAppointmentEvent]
        : [newAppointmentEvent],
    })
  }, [newAppointmentEventData, events])

  const complete = useMemo(() => filterAppointments(appointments, Status.COMPLETE), [appointments])
  const sortedComplete = useMemo(() => sortAppointments(complete), [complete])

  const noAppointments = complete.length === 0

  // eslint-disable-next-line @typescript-eslint/no-empty-function, unicorn/consistent-function-scoping
  const handleDragEnd = () => {}

  const handleEditComplete = async (event: AppointmentEventInput) => {
    if (event) {
      setBusy(true)

      await modifyAppointment({
        variables: {
          input: {
            ...event,
            provider: undefined,
            requesting: undefined,
            room: undefined,
            tags: undefined,
          },
        },
      })

      await refetch()

      setBusy(false)
    }
  }

  const handleAppointmentEditorDismissal = async (event: AppointmentEventInput) => {
    await handleEditComplete(event)
    setAppointmentEditorProps(undefined)
  }

  const handleEdit = (appointment: Appointment) => {
    setAppointmentEditorProps({
      appointment,
      onDismiss: handleAppointmentEditorDismissal,
    })
  }

  const canEditCompletedAppointment = isAdmin(user) || user.roles.includes(Role.DOCTOR)

  return loading || busy ? (
    <Skeleton />
  ) : (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <QueueHeading
          controls={
            <div>
              <DatePicker
                className="w-full md:w-auto text-base pl-3 py-2 text-left sm:text-sm"
                value={date}
                maxDate={new Date()}
                calendarType="US"
                calendarIcon={<CalendarIcon className="w-5 h-5 text-gray-700" />}
                // eslint-disable-next-line unicorn/no-null
                clearIcon={null}
                onChange={(d) => setDate(d as Date)}
              />
            </div>
          }
        >
          Completed on {date.toDateString()}
        </QueueHeading>
        {!noAppointments && <Queue compact fixed status={Status.COMPLETE} data={sortedComplete} onEdit={handleEdit} />}
        {noAppointments && <Empty title={`No completed appointments at ${currentLocation?.name}`} />}
      </DragDropContext>

      {appointmentEditorProps && (
        <AppointmentEditor
          readonly={!canEditCompletedAppointment}
          title="View appointment"
          subtitle="View this completed appointment"
          {...appointmentEditorProps}
        />
      )}
    </>
  )
}

const CompletePage: React.FunctionComponent = () => {
  return (
    <Page title="Complete">
      <CompleteContent />
    </Page>
  )
}

export default CompletePage
